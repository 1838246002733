import React, { FC, memo, SyntheticEvent, useEffect, useMemo } from 'react'
import { Trans } from 'react-i18next'
import clsx from 'clsx'
import { EmptyMessageIcon, If, Spinner } from 'boards-web-ui'
import useBoard from '@hooks/useBoard'
import useBoards from '../../../../hooks/useBoards'
import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { useUpdatesTools } from '../hooks/useUpdatesTools'
import { useUpdatesMessages } from '../hooks/useUpdatesMessages'
import { useUpdatesApi } from '../hooks/useUpdatesApi'
import UpdatesMessage from './UpdatesMessage'
import styles from './UpdatesMessages.module.css'
import { Justify, Align, Size } from '../../../components/Spinner'

type Props = {
  className?: string

  onSelectMessage: () => void
}

const UpdatesMessages: FC<Props> = ({ onSelectMessage, className }) => {
  const { count } = useBoards()
  const { messages, unReadMessages } = useUpdatesMessages()
  const { selectMessage } = useUpdatesApi()
  const { previewUpdateClick, previewUpdatesAll, updatesPaneOpen } =
    useUpdatesEvents()
  const { previousUpdatesTitle } = useUpdatesTranslations()
  const { isOpen } = useUpdatesTools()
  const {
    totalBoardMembersCount,
    isOwnBoard,
    isEditor,
    totalBoardMessagesCount,
  } = useBoard()

  const updatesPaneOpenState = useMemo(() => {
    if (totalBoardMembersCount === 0) return 'members_empty_state'
    return totalBoardMessagesCount === 0
      ? 'messages_empty_state'
      : 'messages_exist'
  }, [totalBoardMembersCount, totalBoardMessagesCount])

  useEffect(() => {
    if (isOpen) {
      previewUpdatesAll(totalBoardMessagesCount, count, unReadMessages.length)
      updatesPaneOpen({
        count: totalBoardMessagesCount,
        state: updatesPaneOpenState,
      })
    }
    // disable esLint because it should trigger only once on isOpen state is equaled to ture
    // eslint-disable-next-line
  }, [isOpen])

  const isExistMessage = totalBoardMessagesCount > 0

  const isLoading = isExistMessage && messages.length === 0

  return (
    <section className={clsx(styles.Root, className)}>
      {(isOwnBoard || isEditor) && (
        <h3 className={styles.Header}>{previousUpdatesTitle()}</h3>
      )}

      <If state={isLoading}>
        <div className={styles.EmptyMessageOrLoadingContainer}>
          <Spinner
            size={Size.LARGE}
            justify={Justify.CENTER}
            align={Align.CENTER}
            spinning
          >
            <span />
          </Spinner>
        </div>
      </If>

      <If state={!isExistMessage}>
        <div className={styles.EmptyMessageOrLoadingContainer}>
          <EmptyMessageIcon className={styles.EmptyMessageIcon} />
          <span className={styles.EmptyMessageText}>
            <Trans i18nKey="messages_feed_no_messages_sub_title" />
          </span>
        </div>
      </If>

      {isExistMessage &&
        messages.map((message) => {
          return (
            <UpdatesMessage
              presentationMode
              message={message}
              key={message.id}
              onClick={(event: SyntheticEvent) => {
                if (
                  (event.target as HTMLElement).tagName.toLowerCase() === 'a'
                ) {
                  return
                }
                onSelectMessage()
                previewUpdateClick()
                selectMessage(message)
              }}
            />
          )
        })}
    </section>
  )
}

export default memo(UpdatesMessages)
