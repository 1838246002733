import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'
import {
  PagesBoardIcon,
  SharedBoardIcon,
  Tag,
  Tooltip,
  TooltipContent,
} from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import { useTranslation } from 'react-i18next'
import { useBoardsMenuTranslations } from '@features/boardsMenu/hooks/useBoardsMenuTranslations'
import styles from './MenuTitle.module.css'
import { roundDigitWithSuffix } from '../../../../utils/roundDigitWithSuffix'

interface Props {
  isPageBoard?: boolean
  icon: ReactNode
  title: ReactNode
  roleText: ReactNode
  hasSharedIcon: boolean
  isSponsored: boolean
  countBoardMembers: number

  onClick?: () => void
  isContentPicker?: boolean

  titleClass?: string
  className?: string
  isRoleNameDisplayed?: boolean
}

export const BoardListItem: FC<Props> = ({
  isPageBoard,
  icon,
  className,
  title,
  titleClass,
  hasSharedIcon,
  isSponsored,
  roleText,
  countBoardMembers,
  isContentPicker,
  onClick,
  isRoleNameDisplayed = true,
}) => {
  const { t } = useTranslation()
  const { digit, suffix } = roundDigitWithSuffix(countBoardMembers)
  const countMembersValue = digit ? `${digit}${suffix}` : undefined

  const { sharedBoard } = useBoardsMenuTranslations()

  return (
    <div className={clsx(styles.TitleWrap, className)} onClick={onClick}>
      <span className={clsx(styles.Icon, isPageBoard && styles.PageBoardIcon)}>
        {isPageBoard ? <PagesBoardIcon fontSize={26} /> : icon}
      </span>

      <div className={styles.BoardInformationContainer}>
        <div
          className={clsx(
            styles.BoardTitle,
            isPageBoard && styles.PageBoardTitle,
          )}
        >
          {isPageBoard ? (
            t('label_web_pages')
          ) : (
            <Tooltip
              container={document.body}
              disabled={isContentPicker}
              data={
                <TooltipContent disabled={isPageBoard}>{title}</TooltipContent>
              }
              className={clsx(styles.Title, titleClass)}
            >
              {title}
            </Tooltip>
          )}
        </div>

        {isPageBoard ? null : (
          <div className={styles.BoardInfo}>
            {isRoleNameDisplayed && (
              <span className={styles.BoardRole}>{roleText}</span>
            )}

            {hasSharedIcon && (
              <Tooltip
                disabled={isContentPicker}
                className={clsx(styles.SharedContainer)}
                data={<TooltipContent>{sharedBoard()}</TooltipContent>}
              >
                <>
                  <span className={styles.SharedDot}>•</span>
                  <IconWrapper className={styles.SharedIconGray} size={18}>
                    <SharedBoardIcon />
                  </IconWrapper>

                  {countMembersValue ? (
                    <span className={styles.CountMembers}>
                      {countMembersValue}
                    </span>
                  ) : null}
                </>
              </Tooltip>
            )}

            {isSponsored && (
              <Tag
                className={styles.Tag}
                fontSize="12px"
                padding="5px 7px"
                lineHeight="14px"
                gray
              >
                Sponsored
              </Tag>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
