import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'
import { useAuth } from '../../../hooks/useAuth'

enum TargetNames {
  FEATURE = 'update_feature',
  PREVIEW = 'preview_update',
  MESSAGE_FEED_SCREEN = 'messages_feed_screen',
  MESSAGE_SCREEN = 'message_screen',
  NEW = 'create_update',
  PRE_POST = 'post_update_needs_confirmation',
  POST = 'post_update',
  MESSAGE_POSTED = 'messages_posted',
  MESSAGE_DELETE = 'app_message_delete',
  DELETE_CLICK = 'delete_update',
  MESSAGES_FREE_BANNER_CLICK = 'app_messages_free_banner_click',
  MESSAGES_OVER_BANNER_CLICK = 'app_messages_over_banner_click',
}

export const useUpdatesEvents = () => {
  const { user } = useAuth()
  const { trigger, buttonClick, screenView } = useLogEvents()

  const previewUpdateClick = () => buttonClick(TargetNames.PREVIEW)
  const previewUpdate = () => screenView(TargetNames.PREVIEW)
  const previewUpdatesAll = (
    countMessages: number,
    countBoards: number,
    countUnread: number,
  ) =>
    screenView(TargetNames.MESSAGE_FEED_SCREEN, {
      count_messages: countMessages,
      count_boards: countBoards,
      count_unread: countUnread,
    })

  const openUpdatesClick = () => buttonClick(TargetNames.NEW)
  const openUpdates = () => buttonClick(TargetNames.NEW)

  const triggerPostUpdates = () => buttonClick(TargetNames.PRE_POST)
  const postUpdates = () => buttonClick(TargetNames.POST)
  const viewPostPanel = () => screenView(TargetNames.NEW)
  const bannerClick = () => screenView(TargetNames.NEW)
  const updateView = (
    updateId: string,
    boardId: string,
    unreadState: boolean,
  ) => {
    screenView(TargetNames.MESSAGE_SCREEN, {
      message_id: updateId,
      board_id: boardId,
      state: unreadState ? 'unread' : 'read',
    })
  }

  const newMessageSend = ({
    boardId,
    value,
    title,
    body,
    membersCount,
  }: {
    boardId: string
    value: number | string
    title: string
    body: string
    membersCount: number
  }) => {
    trigger(TargetNames.MESSAGE_POSTED, {
      message_id: `${value}`,
      board_id: boardId,
      sender_id: user?.uid,
      title,
      body,
      members_count: membersCount,
      date: new Date().toISOString().replace('T', ' ').replace('Z', ''),
      platform: 'web',
    })
  }

  const messageDeleted = (updateId: string) =>
    trigger(TargetNames.MESSAGE_DELETE, { message_id: updateId })

  const messageView = (boardId: string, messageId: string, count: number) =>
    trigger(EventCategories.APP_MESSAGE_VIEW, {
      message_id: `${messageId}`,
      count,
    })

  const confirmationView = () =>
    trigger(EventCategories.APP_MESSAGE_POP_UP_VIEW)
  const sentToastShown = () =>
    trigger(EventCategories.APP_TOAST_UPDATE_SENT_SHOWN)
  const addNameShown = () => trigger(EventCategories.APP_POPUP_ADD_NAME_SHOWN)
  const addNameSaved = (name: string) =>
    trigger(EventCategories.APP_POPUP_ADD_NAME_SAVED, { name })
  const addNameDismissed = () =>
    trigger(EventCategories.APP_POPUP_ADD_NAME_DISMISSED)
  const deletePopUpUpdateShown = () =>
    trigger(EventCategories.APP_POPUP_DELETE_UPDATE_SHOWN)
  const deleteButtonCLick = () => buttonClick(TargetNames.DELETE_CLICK)
  const updatesPaneOpen = ({
    count,
    state,
  }: {
    count: number
    state: string
  }) =>
    trigger(EventCategories.UPDATES_PANE_OPEN, { count_messages: count, state })
  const updatesPaneClose = () => trigger(EventCategories.UPDATES_PANE_CLOSE)
  const messagesTooltipView = () =>
    trigger(EventCategories.APP_MESSAGES_TOOLTIP_VIEW)
  const messagesFreeBannerView = () =>
    trigger(EventCategories.APP_MESSAGES_FREE_BANNER_VIEW)
  const messagesOverBannerView = () =>
    trigger(EventCategories.APP_MESSAGES_OVER_BANNER_VIEW)
  const messagesFreeBannerCLick = () =>
    trigger(TargetNames.MESSAGES_FREE_BANNER_CLICK)
  const messagesOverBannerCLick = () =>
    trigger(TargetNames.MESSAGES_OVER_BANNER_CLICK)
  const boardShowInExtension = () =>
    trigger(EventCategories.APP_PAGES_BOARD_SHOW_IN_EXTENSION)
  const boardHideFromExtension = () =>
    trigger(EventCategories.APP_PAGES_BOARD_HIDE_FROM_EXTENSION)

  return {
    previewUpdateClick,
    previewUpdate,
    previewUpdatesAll,
    openUpdatesClick,
    openUpdates,
    triggerPostUpdates,
    postUpdates,
    viewPostPanel,
    messageView,
    confirmationView,
    newMessageSend,
    messageDeleted,
    bannerClick,
    updateView,
    sentToastShown,
    addNameShown,
    addNameSaved,
    addNameDismissed,
    deletePopUpUpdateShown,
    deleteButtonCLick,
    updatesPaneOpen,
    updatesPaneClose,
    messagesTooltipView,
    messagesFreeBannerView,
    messagesOverBannerView,
    messagesFreeBannerCLick,
    messagesOverBannerCLick,
    boardShowInExtension,
    boardHideFromExtension,
  } as const
}
