import React, { FC, memo, useEffect } from 'react'

import clsx from 'clsx'
import useFeatureFlags from '../../../hooks/useFeatureFlags'
import useRouterParams from '../../../hooks/useRouterParams'
import useFirebaseListeners from '../../../_firebase/hooks/useFirebaseListeners'

import { useUpdatesTools } from './hooks/useUpdatesTools'
import Updates from './components/Updates'
import styles from './AppUpdatesSection.module.css'

const AppUpdatesSection: FC = () => {
  const {
    isOpen: updatesIsOpen,
    isUnAvailable,
    shouldAutoOpenMessagesSection,
  } = useUpdatesTools()
  const { insightsFeatureFlag } = useFeatureFlags()

  const { boardId } = useRouterParams()
  const {
    startBoardUpdatesMessagesListener,
    stopBoardUpdatesMessagesListener,
  } = useFirebaseListeners()

  useEffect(() => {
    if (!isUnAvailable) {
      const [uid, bid] = boardId.split('-')

      if (uid && bid) {
        startBoardUpdatesMessagesListener(uid, bid)
      }
    }
    return () => {
      const [uid, bid] = boardId.split('-')
      stopBoardUpdatesMessagesListener(uid, bid)
    }
    // eslint-disable-next-line
  }, [isUnAvailable, boardId])

  if (isUnAvailable) {
    return null
  }

  return (
    <section
      className={clsx(
        styles.Updates,
        updatesIsOpen && styles.UpdatesIsOpened,
        insightsFeatureFlag && styles.WithNav,
        shouldAutoOpenMessagesSection && styles.UpdatesWithoutAnimation,
      )}
    >
      <Updates />
    </section>
  )
}

export default memo(AppUpdatesSection)
