import { eEnvironment, Node } from 'boards-web-ui'

import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import useBulkActionsPermissions from '@hooks/bulk/useBulkActionsPermissions'
import useFolder from '@hooks/useFolder'
import { ContentPickersEnum } from '@features/pickers'
import useBulk from '@hooks/bulk/useBulk'
import usePageNodeTransformer from '../../nodes/hooks/usePageNodeTransformer'

import { Node as NodeModel, NodeType } from '../../../models'
import FolderListItem from '../../board/components/FolderListItem'
import { buildLayout } from '../../board/helpers/layout'
import NodeSelection from '../../nodes/NodeSelection'
import EmptyContent from './EmptyContent'
import { ReactComponent as EmptyFolderStateIcon } from '../../../../icons/EmptyFolderState.svg'
import useFirebaseListeners from '../../../../_firebase/hooks/useFirebaseListeners'

import styles from './FolderSelection.module.css'

function nodeHasDarkOverlay(type: NodeType) {
  return [NodeType.VIDEO, NodeType.IMAGE].includes(type)
}
function nodesWithRadio(type: NodeType) {
  return ![NodeType.FOLDER, NodeType.PAGE].includes(type)
}

interface Props {
  nodes: NodeModel[]
  boardId: string
  folderId: string
  withRadioBtn?: boolean
  selectFolder: (folderId: string) => void
  toggleNode?: (nodes: NodeModel) => void
  isReadMode?: boolean
  picker: ContentPickersEnum
  isNodeTypeDisabled: (nodeType: NodeType) => boolean
}

const FolderSelection = ({
  nodes,
  boardId,
  folderId,
  withRadioBtn,
  selectFolder,
  toggleNode,
  isNodeTypeDisabled,
  isReadMode,
  picker,
}: Props) => {
  const { folder } = useFolder(boardId, folderId)
  const folderContent = folder?.content || []
  const folderNodes = buildLayout(folderContent)
  const transformPageNode = usePageNodeTransformer()
  const { isCopyDisabled } = useBulkActionsPermissions({ boardId, folderId })
  const { bulk } = useBulk()

  const { startBoardFoldersListener, startBoardPermissionsListener } =
    useFirebaseListeners()

  // Get permissions and folders for shared boards when such board is opened
  // Needed only for Destination Picker
  useEffect(() => {
    if (!isReadMode) {
      return
    }

    const [uid, bid] = boardId.split('-')
    startBoardPermissionsListener(uid, bid)
    startBoardFoldersListener(uid, bid)
  }, [
    isReadMode,
    boardId,
    startBoardPermissionsListener,
    startBoardFoldersListener,
  ])

  const handleOnClick = (node: NodeModel) => {
    if ([NodeType.FOLDER, NodeType.PAGE].includes(node.type)) {
      selectFolder(node.id)
      return
    }

    if (toggleNode) {
      toggleNode(node)
    }
  }

  if (folderContent.length === 0) {
    return (
      <EmptyContent
        icon={<EmptyFolderStateIcon />}
        title={<Trans i18nKey={'label_nothing_here'} />}
      />
    )
  }

  const transformedNodes = folder?.content.map(transformPageNode)

  return (
    <div className={styles.Root} style={{ height: folderNodes.height }}>
      {folderNodes.tiles.map(({ node, rect }, index) => {
        const key = `${node.id}-edit`
        const isSelected = nodes.map((n) => n.id).includes(node.id)
        const isPdfSelected = isSelected && node.type === NodeType.PDF

        const isCopyFlowDisabled =
          picker === ContentPickersEnum.CopyPicker && isCopyDisabled

        const isMoveFlowDisabled =
          picker === ContentPickersEnum.MovePicker &&
          node.type === NodeType.FOLDER &&
          Boolean(bulk.nodes.find(({ id }) => id === node.id))

        const isFolderDisabled = isMoveFlowDisabled || isCopyFlowDisabled

        const isDisabledNode = isReadMode
          ? node.type !== NodeType.FOLDER || isFolderDisabled
          : isNodeTypeDisabled(node.type)

        const nodeToRender = transformedNodes
          ? transformedNodes[index].node
          : null

        return nodeToRender ? (
          <FolderListItem
            key={key}
            rect={rect}
            index={index}
            onDrag={null}
            onDragEnd={null}
          >
            <NodeSelection
              isSelected={isSelected}
              withDarkOverlay={nodeHasDarkOverlay(node.type)}
              withRadioBtn={withRadioBtn ? nodesWithRadio(node.type) : false}
              disabled={isDisabledNode}
              onClick={isDisabledNode ? undefined : () => handleOnClick(node)}
            >
              <Node
                node={nodeToRender}
                nodeBackground={
                  isSelected && !isPdfSelected ? 'white' : undefined
                }
                titleBreakLineThreshold={100}
                environment={eEnvironment.Editor}
              />
            </NodeSelection>
          </FolderListItem>
        ) : null
      })}
    </div>
  )
}

export default FolderSelection
