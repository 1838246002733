import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { PaywallDesktop, PaywallMobile } from 'boards-web-ui'

import useGeoLocation from '@hooks/useGeoLocation'
import usePaywallEvents from '@features/analytics/usePaywallEvents'
import useOverwriteRouteRedirect from '@hooks/useOverwriteRouteRedirect'
import PageHeader from '@features/payments/components/PageHeader'
import useFetchAvailableSubscriptions from '@hooks/useFetchAvailableSubscriptions'
import ConfetisBackground from '@features/payments/components/ConfetisBackground'
import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'
import SubscribeButtonText from '@features/payments/components/SubscribeButtonText'

import { PLANS, PROFILING, SIGN_IN, SIGN_UP } from 'app/constants/routes'
import { usePaywallPlansBulletsProps } from '@features/payments/hooks/usePaywallPlansProps'
import { useDispatch } from 'react-redux'
import subscriptionActions from 'actions/subscription'
import { usePaywallTitle } from '@features/payments/hooks/usePaywallTitle'
import { usePaywallPlanTermSelection } from '@features/payments/hooks/usePaywallPlanTermSelection'
import usePaywallProducts from './hooks/usePaywallProducts'
import { isMobile } from '../../../utils/deviceParser'

const PublicPaywall = () => {
  const { freePlanBullets, businessPlanBullets } = usePaywallPlansBulletsProps()

  const [searchParams] = useSearchParams()
  const campaignId = searchParams.get('campaign')
  const isInCampaignMode = !!campaignId
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loadGeoData } = useGeoLocation()
  const { overwriteRouteRedirect } = useOverwriteRouteRedirect()

  const { paddlePriceIds } = useSubscriptionPlans()
  const { products } = usePaywallProducts(paddlePriceIds)
  const { paywallView, paywallSubscribeClickEvent, paywallTabClickEvent } =
    usePaywallEvents()

  useFetchAvailableSubscriptions(campaignId || undefined)

  useEffect(() => {
    paywallView(campaignId || undefined)
  }, [campaignId, paywallView])

  useEffect(() => {
    loadGeoData()
  }, [loadGeoData])

  const {
    termOptions,
    selectedProductId,
    billedText,
    isMonthlyTermSelected,
    currentPlan,
    discountForPlan,
    handleTermChange,
  } = usePaywallPlanTermSelection({
    items: products?.items,
    currencySymbol: products?.currencySymbol,
  })

  const onSubscribeFreePlan = async () => {
    paywallSubscribeClickEvent('free', undefined, campaignId || undefined)

    navigate(SIGN_UP)
  }

  const onSubscribePlan = async (priceId: string) => {
    const queryParams: { [k: string]: string } = {
      'price-id': priceId,
    }
    if (campaignId) {
      queryParams.campaign = campaignId
    }
    overwriteRouteRedirect(
      [SIGN_IN, PROFILING],
      `${PLANS}?${createSearchParams(queryParams)}`,
    )

    if (currentPlan) {
      paywallSubscribeClickEvent(
        currentPlan.productName,
        priceId,
        campaignId || undefined,
      )
    }

    dispatch(subscriptionActions.resetAvailableSubscriptions())

    navigate(SIGN_UP)
  }

  const Paywall = isMobile ? PaywallMobile : PaywallDesktop

  const { title: paywallTitle, isLongText } = usePaywallTitle({
    currentPlanTitle: currentPlan?.subscription?.title,
  })

  return (
    <ConfetisBackground
      isMobile={isMobile}
      hasConfetis={!!campaignId}
      header={<PageHeader isMobile={isMobile} hideBackButton />}
    >
      {currentPlan && (
        <Paywall
          priceTermValue={selectedProductId}
          onTermChange={handleTermChange}
          planPriceTermSwitcherOptions={termOptions}
          onChangeSubscriptionPlan={paywallTabClickEvent}
          isLongTextTitle={isLongText}
          title={paywallTitle}
          billedText={billedText}
          defaultPlan={'business'}
          plans={{
            free: {
              chipLabel: t('free_plan_title'),
              planPriceTitle: t('free_plan_title'),
              planPriceValue: `${products?.currencySymbol}0`,
              bullets: freePlanBullets,
              confirmButtonText: t('action_get_started'),
              hideSubscribeButton: isInCampaignMode,
              onSubscribePlan: onSubscribeFreePlan,
            },
            business: {
              chipLabel: currentPlan.productName,
              planPriceTitle: currentPlan.productName,
              planPriceValue: `${products?.currencySymbol}${currentPlan.price}`,
              planPriceSubValue: {
                linedThrough: !isMonthlyTermSelected
                  ? `${products?.currencySymbol}${products?.items[0]?.price}`
                  : undefined,
                commonText: t('business_price'),
              },
              planPriceDiscountBadge: !isMonthlyTermSelected
                ? t('label_save_number', {
                    percentage: discountForPlan,
                  })
                : undefined,
              bullets: businessPlanBullets,
              confirmButtonText: <SubscribeButtonText plan={currentPlan} />,
              onSubscribePlan: () => onSubscribePlan(currentPlan.priceId),
            },
          }}
        />
      )}
    </ConfetisBackground>
  )
}

export default PublicPaywall
