import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styles from './ReadMoreText.module.css'

type Props = PropsWithChildren<{
  className?: string
}>

export const ReadMoreText: React.FC<Props> = ({ children, className }) => {
  const { t } = useTranslation()
  const textRef = useRef<HTMLSpanElement | null>(null)
  const [isTextOverflowing, setIsTextOverflowing] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      setIsTextOverflowing(
        textRef.current.scrollHeight > textRef.current.clientHeight,
      )
    }
  }, [])

  return (
    <span ref={textRef} className={[styles.Text, className].join(' ')}>
      {children}
      {isTextOverflowing && (
        <button className={styles.ReadMoreButton}>
          {t('action_read_more')}
        </button>
      )}
    </span>
  )
}
