import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import EditorRoot from '@features/editor/routes/EditorRoot'
import Editor from '@features/editor/routes/Editor'

import { AccountPage } from '@features/account'
import { EmailPreferences } from '@features/account/EmailPreferences'
import { AccountPersonalInfo } from '@features/account/AccountPersonalInfo'
import { AccountSubscriptions } from '@features/account/AccountPageMenuContent/AccountSubscriptions'
import InsightsRout from '@features/insights/routes/InsightsRoot'
import ProfilingPage from '@features/editor/routes/ProfilingPage/ProfilingPage'
import PageSingOut from '@features/auth/routes/PageSingOut/PageSingOut'
import PhoneVerification from '@features/phoneVerification/PhoneVerification'
import ProfilingRoot from '@features/editor/routes/ProfilingRoot'
import WelcomePage from '@features/welcome/WelcomePage'
import PrivatePaywall from '@features/payments/PrivatePaywall'
import CreatePage from '@features/editorPages/CreatePage'
import UpdatePage from '@features/editorPages/UpdatePage'
import TemplatePicker from '@features/templateGallery/TemplatePicker'
import { TemplatePreviewWrapper } from '@features/templateGallery/components/TemplatePreviewWrapper'
import { UniversalLinksBoardsController } from '@features/universalLinksBoards/UniversalLinksBoardsController'
import { MessagesController } from '@features/universalLinksMessages/MessagesController'
import PaywallProviders from '@features/payments/providers/PaywallProviders'

import * as ROUTES from '../../constants/routes'
import { PATH_PARAMS } from '../../constants/routes'
import RedirectWithPrefix from './RedirectWithPrefix'

const PrivateRoutes: React.FC = () => (
  <Routes>
    <Route path={ROUTES.SIGN_OUT} element={<PageSingOut />} />

    <Route path={ROUTES.PROFILING} element={<ProfilingPage />} />

    <Route
      path={ROUTES.PHONE_VERIFICATION}
      element={
        <ProfilingRoot>
          <PhoneVerification />
        </ProfilingRoot>
      }
    />
    <Route path={ROUTES.WELCOME} element={<WelcomePage />} />

    <Route path={ROUTES.ACCOUNT} element={<AccountPage />}>
      <Route
        path={ROUTES.USER_PERSONAL_INFO}
        element={<AccountPersonalInfo />}
      />

      <Route
        path={ROUTES.USER_SUBSCRIPTION}
        element={<AccountSubscriptions />}
      />

      <Route
        path={ROUTES.USER_MESSAGE_PREFERENCES}
        element={<EmailPreferences />}
      />

      <Route path="" element={<AccountPersonalInfo />} />
    </Route>

    <Route
      path={`${ROUTES.MESSAGES}/:${PATH_PARAMS.messageId}`}
      element={<MessagesController />}
    />

    <Route
      path={`${ROUTES.BOARDS}/${PATH_PARAMS.shortcutId}`}
      element={<UniversalLinksBoardsController />}
    />

    <Route path={ROUTES.EDITOR} element={<EditorRoot />}>
      <Route
        path={`${ROUTES.PAGES}/${ROUTES.TEMPLATES}`}
        element={<TemplatePicker isPagesBoardRoute />}
      >
        <Route
          path={PATH_PARAMS.templateId}
          element={<TemplatePreviewWrapper />}
        />
      </Route>
      <Route path={PATH_PARAMS.boardId}>
        <Route path={ROUTES.FOLDER} element={<Editor />}>
          <Route path={ROUTES.NEW_PAGE} element={<CreatePage />} />

          <Route path={ROUTES.EDIT_PAGE} element={<UpdatePage />} />

          <Route path={ROUTES.TEMPLATES} element={<TemplatePicker />}>
            <Route
              path={PATH_PARAMS.templateId}
              element={<TemplatePreviewWrapper />}
            />
          </Route>
        </Route>
      </Route>

      <Route path={ROUTES.INSIGHTS} element={<InsightsRout />} />
    </Route>

    <Route
      path={ROUTES.PLANS}
      element={
        <PaywallProviders>
          <PrivatePaywall />
        </PaywallProviders>
      }
    />
    <Route
      path={`${ROUTES.REDIRECT_PREFIX}/*`}
      element={<RedirectWithPrefix />}
    />
    <Route path={ROUTES.NOT_FOUND} element={<Navigate to={ROUTES.EDITOR} />} />
  </Routes>
)

export default PrivateRoutes
