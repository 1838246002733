import NodeSelectionRadio from './components/NodeSelectionRadio'

import styles from './NodeSelection.module.css'

export interface NodeSelectionProps {
  isSelected: boolean
  withMargin?: boolean
  withRadioBtn?: boolean
  withDarkOverlay?: boolean
  disabled?: boolean
  onClick?: () => void
  children: React.ReactElement
}

const NodeSelection = ({
  disabled,
  isSelected,
  withMargin,
  withRadioBtn,
  withDarkOverlay,
  onClick,
  children,
}: NodeSelectionProps) => {
  const RadioClasses = [styles.Root, styles.WithOverlay]
  if (disabled) RadioClasses.push(styles.Disabled)
  if (isSelected) RadioClasses.push(styles.Selected)
  if (withMargin) RadioClasses.push(styles.WithMargin)
  if (withDarkOverlay) RadioClasses.push(styles.WithDarkOverlay)

  return (
    <div className={RadioClasses.join(' ')}>
      <div className={styles.Overlay} onClick={onClick} />
      {children}
      {onClick && withRadioBtn && (
        <NodeSelectionRadio
          className={styles.Radio}
          classNameUnselected={styles.RadioUnselected}
          classNameUnselectedHover={styles.RadioUnselectedHover}
          selected={isSelected}
          onClick={onClick}
        />
      )}
    </div>
  )
}

export default NodeSelection
