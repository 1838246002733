/* eslint no-console: ["error", { allow: ["error"] }] */
import { useCallback, useEffect, useState } from 'react'
import { PricePreviewResponse } from '@paddle/paddle-js'
import { mergeWith } from 'lodash-es'

import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'
import usePaddleAPI from '@features/payments/hooks/usePaddleAPI'
import transformSubscription from '@features/payments/utils/subscriptionTransformer'
import transformformPricePreview from '../utils/productTransformer'

const usePaywallProducts = (productIds: string[]) => {
  const { getProductPrices } = usePaddleAPI()
  const { plans } = useSubscriptionPlans()
  const [sourceProductIds, setSourceProductIds] = useState<string[]>([])
  const [sourceProducts, setProducts] = useState<PricePreviewResponse>()
  const subscriptions = transformSubscription(plans)
  const products = transformformPricePreview(subscriptions, sourceProducts)

  const priceIdWithDiscountId = plans.reduce<Record<string, string>>(
    (acc, current) => {
      const productDiscountId = current.subscriptions.reduce(
        (accSubscription, next) => ({
          ...accSubscription,
          [next.productId]: next.productDiscountId,
        }),
        {},
      )

      return { ...acc, ...productDiscountId }
    },
    {},
  )

  const fetchProducts = useCallback(async () => {
    try {
      const pricesArray = await Promise.all(
        Object.keys(priceIdWithDiscountId).map((priceId) =>
          getProductPrices([priceId], priceIdWithDiscountId[priceId]),
        ),
      )

      const mergedPrices = pricesArray.reduce<PricePreviewResponse | undefined>(
        (acc, currentValue) =>
          mergeWith(acc, currentValue, (objValue, srcValue) =>
            Array.isArray(objValue)
              ? Array.from(new Set([...objValue, ...srcValue]))
              : undefined,
          ),
        undefined,
      )

      if (mergedPrices) {
        setProducts(mergedPrices)
      }
    } catch (error) {
      console.error(error)
    }
  }, [priceIdWithDiscountId, getProductPrices])

  useEffect(() => {
    if (
      productIds.length > 0 &&
      !productIds.every((id) => sourceProductIds.includes(id))
    ) {
      fetchProducts()
      setSourceProductIds(productIds)
    }
  }, [productIds, sourceProductIds, fetchProducts])

  const getProductByPeriod = (productId: string) => {
    if (products) {
      return sourceProducts?.data.details.lineItems.find(
        (p) => p.price.id === productId,
      )
    }

    return undefined
  }

  return {
    products,
    subscriptions,
    getProductByPeriod,
  } as const
}

export default usePaywallProducts
