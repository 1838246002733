import { useLocation, useNavigate } from 'react-router-dom'
import { theme, useDialog } from 'boards-web-ui'

import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { useDispatch } from 'react-redux'
import { IOpenNode } from '../models/nodes'

import NodePreview from '../../nodePreview/NodePreview'
import { Folder } from '../../../models'
import { toastActions } from '../../../../actions'

const useNodeActions = (
  folder?: Folder,
  isSharedFolderMode?: boolean,
  canCopyContentOfSharedFolder?: boolean,
  sharedBoardId?: string,
  sharedFolderId?: string,
): IOpenNode => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { open, close } = useDialog()
  const { isFreePlanUser } = useReverseTrialContext()
  const dispatch = useDispatch()

  const previewNode = (nodeId: string, hideParents?: boolean) => {
    if (isFreePlanUser) {
      dispatch(toastActions.closeToastMessage())
    }

    return open(
      <NodePreview
        nid={nodeId}
        close={close}
        folder={folder}
        isSharedFolderMode={isSharedFolderMode}
        canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
        sharedBoardId={sharedBoardId}
        sharedFolderId={sharedFolderId}
      />,
      {
        fullScreen: true,
        closeOnEscapeButton: true,
        closeOnOverlayClick: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        withFullScreenDialogContainer: false,
        hideParents,
      },
    )
  }

  const openFolder = (boardId: string, folderId: string) =>
    navigate(`/${boardId}/${folderId}/${search}`)

  return {
    openFolder,
    previewNode,
  }
}

export default useNodeActions
