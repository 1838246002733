import useBoard from './useBoard'

const useBoardActions = (params?: { boardId?: string; folderId?: string }) => {
  const {
    isReadOnly,
    isActive,
    isFolderEmpty,
    canShare,
    allowCopy,
    board,
    folder,
  } = useBoard(params)

  return {
    canUndoAndRedo:
      isActive && !isReadOnly && !folder?.isPage && !board?.isPageBoard,
    canShare: isActive && !folder?.isPage && !board?.isPageBoard,
    canAddMembers: isActive && canShare && !board?.isPageBoard,
    canAddContent: (isActive && !isReadOnly) || board?.isPageBoard,
    canSelectNodes:
      isActive &&
      !isFolderEmpty &&
      allowCopy &&
      !folder?.isPage &&
      !board?.isPageBoard,
    canDeleteBoard: !isReadOnly && board?.isOwn && !board?.isPageBoard,
    canLeaveBoard: !board?.isOwn,
    canPreviewNode: isActive,
    canEditBoard: (isActive || board?.isPageBoard) && !isReadOnly,
    canEditBoardTitle:
      (isActive && !(isReadOnly || board?.receivedContent)) ||
      board?.isPageBoard,
    canEditFolderTitle: isActive && !isReadOnly,
    canViewActivateBoardButton: !isActive && !board?.isPageBoard,
  }
}

export default useBoardActions
