import { QueryDocumentSnapshot } from 'firebase/firestore'
import { parseBoolean } from '@helpers/bool'
import { Board, BoardItem, BoardType } from '../../../app/models'

export const transformBoard = (
  item: BoardItem,
  doc: QueryDocumentSnapshot,
): Board => {
  const data = doc.data()

  return {
    id: item.id,
    rootFolderId: item.rootFolderId,
    icon: data?.folder?.properties?.icon,
    title:
      data?.type === BoardType.PAGES
        ? 'Web Pages'
        : data?.folder?.properties?.title,
    isPageBoard: data?.type === BoardType.PAGES,
    isOwn: item.isOwn,
    receivedContent: !!data.receivedContent,
    action: {
      id: data.action,
      undoHead: data.undoHead,
      redoHead: data.redoHead,
    },
    options: {
      checkoutAllowed: parseBoolean(data?.options?.checkoutAllowed, true),
      copyAllowed: parseBoolean(data?.options?.copyAllowed, true),
      formsAllowed: parseBoolean(data?.options?.formsAllowed, true),
      shareAllowed: parseBoolean(data?.options?.shareAllowed, true),
      insightsAllowed: parseBoolean(data?.options?.insightsAllowed, false),
      writeAllowed: parseBoolean(data?.options?.writeAllowed, true),
      shareFolderAllowed: parseBoolean(data?.options?.shareFolderAllowed, true),
      notCountedTowardsLimit: parseBoolean(
        data?.options?.notCountedTowardsLimit,
        false,
      ),
    },
    summary: {
      messages: {
        threads: data.summary?.messages?.threads || 0,
        total: data.summary?.messages?.total || 0,
      },
      permissions: {
        read: data.summary?.permissions?.read || 0,
        share: data.summary?.permissions?.share || 0,
        total: data.summary?.permissions?.total || 0,
        write: data.summary?.permissions?.write || 0,
      },
    },
  } as Board
}
