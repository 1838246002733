import { doc, setDoc } from 'firebase/firestore'
import omitDeep from '@helpers/omitDeep'
import { contentFunctions as functions } from './functions'
import { callFirebaseFunction, clearProperties } from './utils'
import auth from './auth'
import db from './storage'

export const createBoard = ({ owner, key, properties }) => {
  const args = {
    owner,
    key,
    properties: clearProperties(properties),
  }
  return callFirebaseFunction(functions, 'createBoard', args)
}

export const activateBoard = ({ board }) => {
  const args = {
    board,
  }

  return callFirebaseFunction(functions, 'activateBoard', args)
}

export const addFolder = ({ board, folder, key, properties }) => {
  const args = { board, folder, key, properties: clearProperties(properties) }
  return callFirebaseFunction(functions, 'addFolder', args)
}

export const addPage = ({ board, folder, key, properties, location }) => {
  const args = {
    board,
    folder,
    key,
    properties: clearProperties(properties),
    ...(location && { location }),
  }
  return callFirebaseFunction(functions, 'addPage', args)
}

export const addContent = async ({ board, folder, key, properties }) => {
  const args = {
    board,
    folder,
    key,
    properties: [clearProperties(properties)],
  }

  return callFirebaseFunction(functions, 'addContent', args)
}

export const postUpdatesMessage = ({ board, properties, key, senderName }) => {
  let args = { board, properties: clearProperties(properties), key }
  if (senderName) {
    args = {
      ...args,
      senderName,
    }
  }
  return callFirebaseFunction(functions, 'addMessage', args)
}

export const deleteUpdatesMessage = ({ boardId, mid }) => {
  const args = { board: boardId, message: mid }
  return callFirebaseFunction(functions, 'deleteMessage', args)
}

export const markUpdatesMessagesAsRead = ({ board, messages }) => {
  const args = { board, messages }
  return callFirebaseFunction(functions, 'markMessagesAsRead', args)
}

export const setProperties = ({ board, folder, node, properties }) => {
  const filteredProperties = clearProperties(
    omitDeep(properties, ['form.encryptedEmail']),
  )

  const args = {
    board,
    folder,
    node,
    properties: filteredProperties,
  }
  return callFirebaseFunction(functions, 'setProperties', args)
}

export const moveNodes = ({ board, folder, nodes, fromFolder, before }) => {
  const args = {
    board,
    folder,
    nodes,
    fromFolder,
    before,
  }
  return callFirebaseFunction(functions, 'moveNodes', args)
}

export const deleteNodes = ({ board, folder, nodes }) => {
  const args = { board, folder, nodes }
  return callFirebaseFunction(functions, 'deleteNodes', args)
}

export const deleteBoard = ({ board }) => {
  const args = { board }
  return callFirebaseFunction(functions, 'deleteBoard', args)
}

export const undo = ({ board, action }) => {
  const args = { board, action }
  return callFirebaseFunction(functions, 'undo', args)
}

export const redo = ({ board, action }) => {
  const args = { board, action }
  return callFirebaseFunction(functions, 'redo', args)
}

export const logUsage = ({ event, board, nodes }) => {
  // TODO remove next check when issue with empty board arguments will be fixed;
  if (!board) {
    // eslint-disable-next-line
    console.log('Event with empty board argument: ', event)
    return new Promise((resolve) => {
      resolve(undefined)
    })
  }

  const args = {
    event,
    board,
    nodes,
  }
  return callFirebaseFunction(functions, 'logUsage', args)
}

export const recordSelection = async ({ boardId, folderId, nodes }) => {
  const args = {
    board: boardId,
    folder: folderId,
    nodes,
  }

  return callFirebaseFunction(functions, 'recordSelection', args)
}

export const setProfileInformation = ({ info }) => {
  const { uid } = auth.currentUser
  return setDoc(doc(db, `users/${uid}`), info, { merge: true })
}

export const addMembers = ({ board, emails, permission }) => {
  const args = {
    board,
    emails,
    permission,
  }

  return callFirebaseFunction(functions, 'addMembers', args)
}

export const setPermission = ({ board, email, permission }) => {
  const args = {
    board,
    email,
    permission,
  }

  return callFirebaseFunction(functions, 'setPermission', args)
}

export const removeMembers = ({ board, emails }) => {
  const args = {
    board,
    emails,
  }

  return callFirebaseFunction(functions, 'removeMembers', args)
}

export const joinSharedBoard = ({ key }) => {
  const args = {
    key,
  }

  return callFirebaseFunction(functions, 'joinBoard', args)
}

export const setBoardsOrder = async (order) => {
  const args = {
    boards: order,
  }
  return callFirebaseFunction(functions, 'orderBoards', args)
}
